<template>
  <div>
    <audio ref="audio" controls :src="src" />
  </div>
</template>

<script>
import Plyr from "plyr";
import "plyr/dist/plyr.css";
export default {
  name: "plyrAudio",
  props: {
    src: String,
    controls: {
      type: Array,
      required: false,
      default: () => ["play", "progress", "current-time", "mute", "volume"]
    }
  },

  mounted() {
    new Plyr(this.$refs["audio"], {
      controls: this.controls,
      debug: process.env.VUE_APP_MODE !== "production",
      iconUrl: require("plyr/dist/plyr.svg"),
      blankVideo: require("@/assets/plyr/blank.mp4")
    });
  }
};
</script>
